import React, { useContext, useLayoutEffect } from "react";
import { useState, useRef, useCallback } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SearchBar from "../navbar/searchbar";
import Axios from "axios";
import { Dropdown } from "react-bootstrap";
import Card from "../card/card";
import SearchFooter from "../footer/searchFooter";
import { Url } from "../../..";
import { Box, CircularProgress } from "@mui/material";
import { searchLoading } from "../../../App";
function Properties() {
  const [properties, setproperties] = useState([]);
  const [page, setpage] = useState(1);
  const [city, setcity] = useState("");
  const [cityName, setcityName] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [guests, setguests] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const { setSarchload , sarchload , dataSearch , setDataSearch } = useContext(searchLoading);
  
  const location = useLocation();
  // useEffect(() => {
  //   setLoading(true);
  //   }, [] )
  useEffect(() => {
    setproperties(dataSearch?.properties);
  }, [dataSearch])

  useEffect(() => {
    setTimeout(async () => {
      setcity(location.state.city);
      setstartDate(location.state.startDate);
      setendDate(location.state.endDate);
      setguests(location.state.guests);
      // setproperties(location.state.data.properties);
      setcityName(location.state.cityName);
      // if (location.state.loading) {

      //   setLoading(location.state.loading);
      // } 
      

    }, 1000);
  }, [location.state]);
  const lastPropertyElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLoading(true);
          const newPage = page + 1;
          setpage(newPage);
          const { data } = await Axios.get(
            Url+`/api/search?city=` +
            city +
            "&startDate=" +
            startDate +
            "&endDate=" +
            endDate +
            "&guests=" +
            guests +
            "&pageSize=" +
            8+
            "&pageNumber=" +
            newPage
          );
          const proper = [...dataSearch];
          for (let i = 0; i < data.properties.length; i++) {
            proper.push(data.properties[i]);
          }
          if (data.properties.length == 0) setHasMore(false);
          setDataSearch(proper);
          setLoading(false);
        }
      });
      if (node) observer.current.observe(node);
    },
  );
  return (
    <React.Fragment>
      <SearchBar />
      {sarchload ? (
          <>
          <div className="container mb-5 search-mr">
            <Box sx={{ display: "flex", margin: "100px auto" , justifyContent:"center"}}>
              <CircularProgress style={{ color: "#073448" }} />
            </Box>

          </div>
          </>
        ) : (
      <div className="container mb-5 search-mr">
        <div className="row ">
          <hr id="horizintal" className="hide-row" />
        </div>
        <div className="row">
          {cityName!==''?  <div className="row">
              <div className="col-12 mb-4">
              { properties?.length > 0 &&  <span className="light-text blue-color prop-light">Lebanon / {cityName}</span> }
              </div>
            </div>:''}
            {cityName!==''?    <div className="row ">
              <div className="col-12">
                
              { properties?.length > 0 &&  <span className="blue-color semi-bold-3">Popular places to stay in {cityName}</span>}
                </div>
              </div>
              :  
              <div className="row ">
              <div className="col-12">
                
                 { properties?.length > 0 && <span className="blue-color semi-bold-3">Popular places</span>}
                </div>
              </div>}
        
            
            <div className="row">
                {properties?.map((property, index) =>
                  properties?.length === index + 1 ? (
                    <div
                      className="col-sm-12 col-md-6 col-lg-4 mb-3 mt-3"
                      ref={lastPropertyElementRef}
                    >
                      <Card Card={property} type={"property"} />
                    </div>
                  ) : (
                    <div className="col-sm-12 col-md-6 col-lg-4 mb-3 mt-3">
                      <Card Card={property} type={"property"} />
                    </div>
                  )
                )}
              </div>
        </div>
      </div> )}
      <SearchFooter />
    </React.Fragment>
  );
}

export default Properties;
