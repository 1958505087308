import React, { Component } from "react";
import logo from "../../../svg/logo.svg";
import "./footer.css";
import { Link } from "react-router-dom";
class Footer extends Component {
  render() {

    var screenWidth=localStorage.getItem("screenWidth");
    return (
      screenWidth>=468?(
      <footer className="footer">
        <div className="container footer-container">
          <div className="row">
            <div className="info col-lg-3 col-sm-4">
              <div className="title-bold">
                <span>Contact us:</span>
              </div>

              <div className="title-bold">
                <span>Sales</span>
              </div>

              <span className="text-reg">
              <a href={`mailto:info@chaletlebanon.com`} style={{color:"white"}}>info@chaletlebanon.com</a>
                
                </span>
              <br></br>
              <span className="text-reg">
              <a href={`tel:+961 70 970 837`} style={{color:"white"}}>+961 70 970 837</a>
              {/* +961 70 970 837 */}
                
                </span>
              <br></br>
            </div>

            <div className="info col-lg-3 col-sm-4">
              <div className="title-bold">
                <span>Locations</span>
              </div>

              <span className="text-reg">Beirut, Lebanon</span>
              <div className="title-bold pad-top-7">
                <span>FAQs</span>
              </div>
            </div>
            <div className="info col-lg-3 col-sm-4">
              <div className="title-bold">
                <span>Follow Us</span>
              </div>
              <div className="row">
                

                <a target={"_blank"} href="https://instagram.com/chaletlebanon">
                <div className="col-2">
                  <i className="fab fa-instagram footer-socials"></i>
                </div>
                </a>

                <a target={"_blank"} href="https://www.tiktok.com/@chaletlebanon">
                <div className="col-1">
                  <i className="fa-brands fa-tiktok footer-socials"></i>
                </div></a>

              </div>
            </div>
            <div className="col-lg-3 col-sm-4 d-flex justify-content-end">
              <img className="foo-logo" src={logo} alt="" />
            </div>
          </div>
          <div className="row ">
            <div className="policies-sec reg-text">
              <p>
                {" "}
                © {new Date().getFullYear()} Chalet Lebanon . All rights reserved. Terms and
                Conditions · Privacy Policy
              </p>
              <p>
                {" "}
                Designed & Developed by <a href="https://dcentrify.com">dcentrify.com</a> 
              </p>
            </div>
          </div>
        </div>
      </footer>
      )
      
      
      
      
      
      
      
      :(

        <footer className="footer-mobile">
        <div className=" footer-container-mobile">
         
          
               <div className="info-container-mobile">

                <div className="info1">
                  <h1 className="mobile-footer-headers">Contact us:</h1>
                  <h1 className="mobile-footer-headers">Sales</h1>
                  <legend className="mobile-footer-legend">
                  <a href={`mailto:info@chaletlebanon.com`} style={{color:"white"}}>info@chaletlebanon.com</a>
                    </legend>
                  <legend className="mobile-footer-legend">
                  <a href={`tel:+961 70 970 837`} style={{color:"white"}}>+961 70 970 837</a>
                    {/* +961 70 970 837 */}
                    </legend>
                  
                </div>


                <div className="info2">
                  <h1 className="mobile-footer-headers">Locations</h1>
                  
                  <legend className="mobile-footer-legend"> Beirut, Lebanon</legend><br /> 
                  <Link className="FAQ-link" to="/FAQ">FAQs</Link>

                 
              
                </div>
                
                </div> 

                <div className="social-footer-mobile">

                <a target={"_blank"} href="https://instagram.com/chaletlebanon">
                <div className="instagram-mobile">
                  <i className="fab fa-instagram footer-socials"></i>
                </div>
                </a>

                <a target={"_blank"} href="https://www.tiktok.com/@chaletlebanon">
                <div className="tiktok-mobile">
                  <i className="fa-brands fa-tiktok footer-socials"></i>
                </div></a>

                </div>


                <div className="copyright-mobile-ctr">
                <legend className="mobile-footer-legend">
                
                © {new Date().getFullYear()} Chalet Lebanon . All rights reserved. Terms and
                Conditions · Privacy Policy
              </legend>
              <legend className="mobile-footer-legend">
                
              {" "}
                Designed & Developed by <a href="https://dcentrify.com">dcentrify.com</a> 
              </legend>
                </div>

             
        </div>
      </footer>

      )
    );
  }
}

export default Footer;
