import React, { Component } from "react";
import Axios from "axios";
import "./FAQ.css";
import { Url } from "../../../..";
import { th } from "date-fns/locale";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
class FAQ extends Component {
  state = {
    questions: [],
    active: "",
    activeData: [],
    activeAnswer: "",
    search: "",
    searched: [],
    mobile: false,
    loading:false,
    windowWidth:window.innerWidth
  };
  constructor(props) {
    super(props);
    this.handleResize = this.handleResize.bind(this);
  }
  removeLocalStorage = () => {
    localStorage.removeItem('city')
    localStorage.removeItem('cityObj')
    localStorage.removeItem('checkIn')
    localStorage.removeItem('checkOut')
    localStorage.removeItem('guest')

  }
  componentDidMount = async () => {
    this.removeLocalStorage();
    console.log(this.state.windowWidth);
    window.addEventListener('resize', this.handleResize);
    var { data } = await Axios.get(Url+"/api/home/faq");
    this.setState({ questions: data.questions, active: data.questions[0] });
    var { data } = await Axios.get(
      Url+"/api/home/faq/" + data.questions[0]._id
    );
    this.setState({ activeData: data.questions });
  };
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  handleResize() {
    this.setState({ windowWidth: window.innerWidth });
  }
  handleActive = async (q) => {
    this.setState({ active: q });
    this.setState({ activeAnswer: "" });
    const { data } = await Axios.get(
      Url+"/api/home/faq/" + q._id
    );
    this.setState({ activeData: data.questions });
  };
  handleActiveAnswer = (q) => {
    if (q._id === this.state.activeAnswer._id)
      this.setState({ activeAnswer: "" });
    else this.setState({ activeAnswer: q });
  };
  handleSearch = ({ currentTarget: input }) => {
    this.setState({ search: input.value });
  };
  handleSearchSubmit = async () => {
    console.log("search");
    console.log(this.state.questions);
    if (this.state.search !== "") {
     this.setState({loading: true});
      const res = this.state.questions.filter((obj) =>
        Object.values(obj).some((val) => val.includes(this.state.search))
      );
      this.setState({ activeAnswer: "" });
      console.log(res);
      if (res?.length > 0) {
        const { data } = await Axios.get(
          Url+"/api/home/faq/" + res[0]?._id
        );
        this.setState({
          searched: res,
          active: res[0],
          activeData: data.questions,
          loading:false
        });

      } else {
        this.setState({loading: false});
        toast.warn("no data found")
      }
    }
  };

  render() {
    return (
      <>
        <div className="">
          <div className="row bac-sec-faq">
            <div className="container FAQ-txt-ctr">
          <h1> We're Here to Help!</h1>
            <p className="FAQ-txt reg-text">
             
              Search through our articles to find the answer to your questions.
            </p>
            </div>
            <button
              className="nav-search-btn-min-faq d-none"
              
              
            >
              {" "}
              Search
            </button>
            <div className="d-none">
              <div className="bi-x " >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </div>
              <div className="conatainer">
                <div className="form-group ">
                  <input
                    name="search"
                    type="text"
                    className="search-input"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Search throug our articals"
                    onChange={this.handleSearch}
                  />
                </div>

                <div className="text-center mt-4">
                  <button
                    className="moblie-btn"
                    onClick={this.handleSearchSubmit}
                  >
                    {" "}
                    {this.state.loading?<CircularProgress size={25} style={{ color: "#fff" }} />:"Search"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="container search ">
            <div className="row search-row pr-0 container">
              <div className="col-9">
                <input
                  type="text"
                  id="inputPassword5"
                  className="search-box light-text2"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Search through our articles"
                  onChange={this.handleSearch}
                />
              </div>

              <div className="col-3">
                <button
                  className="nav-search-btn"
                  onClick={this.handleSearchSubmit}
                >
               
                 {this.state.loading?<CircularProgress size={25} style={{ color: "#fff" }} />:"Search"}
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="nav-search-container">
          <div className="row bac-sec-faq">
            <h1 className="semi-bold">We're Here to Help!</h1>
            <span className="light-text">
              Search through our articles to find the answer to your questions.
            </span>
          </div>
          <div className="container search">
            <div className="row search-row">
              <div className="col-10 cus">
                <input
                  type="text"
                  id="inputPassword5"
                  className="search-box light-text"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Search through our articles"
                  onChange={this.handleSearch}
                />
              </div>

              <div className="col-2">
                <button className="nav-search-btn" onClick={this.handleSearchSubmit}> Search</button>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container qu-body">
          <div className="row" style={{marginLeft:this.state.windowWidth < 400&&0}}>
            {this.state.searched.length > 0
              ? this.state.searched.map((q) => (
                  <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 pl-0">
                    <div
                      className={
                        this.state.active._id === q._id
                          ? "qu-Card-active"
                          : "qu-Card"
                      }
                      rule="button"
                      key={q._id}
                      onClick={() => this.handleActive(q)}
                    >
                      <span className="semi-bold">{q.question}</span>
                    </div>
                  </div>
                ))
              : this.state.questions.map((q) => (
                  <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 pl-0">
                    <div
                      className={
                        this.state.active._id === q._id
                          ? "qu-Card-active"
                          : "qu-Card"
                      }
                      rule="button"
                      key={q._id}
                      onClick={() => this.handleActive(q)}
                    >
                      <span className="semi-bold">{q.question}</span>
                    </div>
                  </div>
                ))}
          </div>
          <div className="row ml-2 mb-4 mt-5">
            <h6 className="semi-bold blue-color">
              {this.state.active.question}
            </h6>
          </div>
          {this.state.activeData.map((q) => (
            <div className="row" style={{margin:this.state.windowWidth < 400&&0}}>
              <div
                style={{marginLeft:this.state.windowWidth < 400&&6}}
                className={
                  this.state.activeAnswer._id === q._id
                    ? "qu-op-Card-active"
                    : "qu-op-Card"
                }
                key={q._id}
                onClick={() => this.handleActiveAnswer(q)}
              >
                <span className="semi-bold">
                  {this.state.activeAnswer._id === q._id
                    ? q.answer
                    : q.question}
                </span>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}

export default FAQ;
