import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input/input";
import PhoneInput from "react-phone-number-input";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];
    const data = { ...this.state.data };
    if (input.name === "attachment") {
      const images = [];
      for (let i = 0; i < input.files.length; i++) {
        images.push(input.files[i]);
      }
      data[input.name] = images;
    } else {
      data[input.name] = input.value;
    }
    this.setState({ data, errors });
  };

  renderButton(label, className) {
    return (
      <button disabled={this.validate()} className={className}>
        {label}
      </button>
    );
  }

  renderInput(name, label, type = "text", inError = "", className) {
    const { data, errors } = this.state;
    if (type === "file")
      return (
        <Input
          type={type}
          name={name}
          label={label}
          onChange={this.handleChange}
          inError={inError}
          error={errors[name]}
          className={className}
          multi={true}
        />
      );

      else if (type === "tel") {
        return (
          <div className="form-group">
            <label htmlFor={name}>{label}</label>
            <PhoneInput
              name={name}
              value={data[name]}
              onChange={(value) => this.handleChange({ currentTarget: { name, value } })}
              defaultCountry="LB"
              international
            />
            {errors[name] && (
              <div className="alert alert-danger">{errors[name]}</div>
            )}
          </div>
        );
      }

    else
      return (
        <Input
          type={type}
          name={name}
          value={data[name]}
          label={label}
          onChange={this.handleChange}
          inError={inError}
          error={errors[name]}
          className={className}
        />
      );
  }
}

export default Form;
