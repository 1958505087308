import React, { Component, useContext } from "react";
import SearchBar from "../navbar/searchbar";
import { Navigation } from "swiper";
import "./home.css";
import Slider from "../slider/slider";
import { Swiper, SwiperSlide } from "swiper/react";
import SearchFooter from "../footer/searchFooter";
import Axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { useRef, useCallback } from "react";
import Card from "../card/card";
import { Url } from "../../..";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "./calendar";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { searchLoading } from "../../../App";
function Home() {
  const [cities, setCities] = useState([]);
  const [categories, setcategories] = useState([]);
  const [active, setactive] = useState("Cities");
  const [destinations, setdestinations] = useState([]);
  const [page, setpage] = useState(1);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const dispatch = useDispatch()
  const observer = useRef();
  const { removeLocalStorage } = useContext(searchLoading);
  const lastPropertyElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver(async (entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setLoading(true);
          const newPage = page + 1;
          setpage(newPage);
          const { data } = await Axios.get(
            Url+`/api/home/featured?pageSize=` +
              12 +
              "&pageNumber=" +
              page
          );
          console.log(data.cities);
          
               
          const proper = [...destinations];
          for (let i = 0; i < data.properties.length; i++) {
            proper.push(data.properties[i]);
          }
          if (data.properties.length === 0) setHasMore(false);
          setdestinations(proper);
          setLoading(false);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  const getData = async() => {
    setLoading(true);
    const { data } = await Axios.get(Url+"/api/home");
    console.log(data.cities);
    localStorage.setItem('cities', JSON.stringify(data.cities))
    setCities(data.cities?.filter(d => d?.stays > 0 ));
    setcategories(data.categories?.filter(d => d?.stays > 0 ));
    setLoading(false);
    }
  



  useEffect(() => {
    // const cities = useSelector(state => state.cities)
    // console.log("cities",cities);
    removeLocalStorage()
    getData()
  }, []);
  // if(cities){
    if (localStorage.getItem('cities')) {
      let newCities = JSON.parse(localStorage.getItem('cities'))
      console.log(newCities);
      // if (newCities)
      // dispatch(setCities([...newCities ]));
               } 
  //   dispatch(setCities(...cities))
  // }
  // const cities1 = useSelector(state => state.cities)
  // console.log("cities",cities1);

  const handleChangeActive = async (active) => {
    if (active === "Destinations" && destinations.length === 0) {
      const { data } = await Axios.get(
        Url+`/api/home/featured?pageSize=` + 
          12 +
          "&pageNumber=" +
          1
      );
      setactive(active);
      setdestinations(data.properties);
      setCount(data.count);
    } else if (active === "Destinations" && destinations.length !== 0) {
      setactive(active);
      setpage(1);
    } else {
      setactive(active);
    }
  };
  return (
    <React.Fragment>
      <SearchBar />

     
      <div className="container cards-container  mb-5 pt-5">
     
         {/* <Calendar/>   */}
        
        <div className="row color-p ">
          <p className="top-destinations-txt">These top destinations are just a click away</p>
        </div>

        <div className="row position-relative mb-4 top-cities">
          <div>
            <a
              className={
                active === "Cities" ? "home-a green-color" : "blue-color home-a"
              }
              onClick={() => handleChangeActive("Cities")}
            >
              Top Cities
            </a>
          </div>
          <div>
            <a
              className={
                active === "Destinations"
                  ? "home-a green-color ml-4"
                  : "blue-color home-a ml-4"
              }
              onClick={() => handleChangeActive("Destinations")}
            >
              Top Destinations
            </a>
          </div>
          <hr id="horizintal" />
          <hr
            className={
              active === "Cities"
                ? "active-line active-c"
                : " active-line active-d"
            }
          />
        </div>
      {loading ? 
      <> 
      <Box sx={{ display: 'flex' ,margin:"100px auto" }}>
      <CircularProgress style={{color:"#073448"}}/>
      </Box>
      </>
      :
      <>
        {active === "Cities" ? (
          <>
            {" "}
            <div className="row position-relative slider-1 sliders">
              <Slider data={cities} type={"city"} />
            </div>
            <div className="row mt-5 color-p find-spaces">
              <p>Find spaces that suit your style</p>
            </div>
            <div className="row position-relative slider-2 sliders">
              <Slider data={categories} type={"category"} />
            </div>
          </>
        ) : (
          <Swiper
          freeMode={true}
          navigation={true}
          modules={[Navigation]}
          grabCursor={true}
          breakpoints={{
            0: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            500: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
    
            991: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            1300: {
              slidesPerView: 4,
              spaceBetween: 0,
            },
            2560: {
              slidesPerView: 6,
              spaceBetween: 0,
            },
          }}
        >
          
          <div className="row top-destinations-ctr">
            {destinations.map((property, index) =>
              destinations.length === index + 1 ? (
                <SwiperSlide key={index}>
                <div
                  className="col-sm-12 col-md-12 col-lg-12 mb-5 no-padding2"
                  ref={lastPropertyElementRef}
                >
                 
                  <Card Card={property} type={"property"} />
                  
                </div>
                </SwiperSlide>
              ) : (
                <SwiperSlide key={index}>
                <div className="col-sm-12 col-md-12 col-lg-12 mb-5 no-padding2">
                 
                  <Card Card={property} type={"property"} />
                  
                </div>
                </SwiperSlide>
              )
            )}
          </div>
          </Swiper>  )}
      </>
    }
      </div>
    
      <SearchFooter />
    </React.Fragment>
  );
}
export default Home;
