import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import logo from "../../../svg/logo.svg";
class Header extends Component {
  state = {
    width:window.innerWidth
  };

  
  componentDidMount(){
    console.log(window.innerWidth);
    this.setState({
      width:window.innerWidth
    })
  }
  render() {
    
    const screenWidth=localStorage.getItem("screenWidth");
    
    
    return (
      
//      this.state.width>=768?(  
<>
      <nav className="navbar navbar-light navbar-default container">
        <Link to={`/add`} className="navbar-brand navbar-brand1">
          List your property
        </Link>
        
        <Link to={`/FAQ`} className="navbar-brand navbar-brand2">
          FAQs
        </Link>
      </nav>

<div className="logo-nav-container-desk">
<Link to={`/`}>
  {" "}
  <img className="logo" src={logo} alt="" />
</Link>
</div>
</>
//     ):
//     (
//       <nav className="navbar navbar-light navbar-default container">
//      <div className="logo-nav-container">
//       <Link to={`/`}>
//         {" "}
//         <img className="logo" src={logo} alt="" />
//       </Link>
//       </div>
//     </nav>
//     )
    );
  }
}

export default Header;
