import React from "react";
import "./input.css";
const Input = ({ name, label, className, error, inError, multi, ...rest }) => {
  return (
    <div className="form-group">
      <input
        {...rest}
        name={name}
        id={name}
        className={className}
        placeholder={label}
        multiple={multi}
      />
      {error && <div className="alert alert-danger">{error}</div>}
      {inError && <div className="alert alert-danger">{inError}</div>}
    </div>
  );
};

export default Input;
