import React, { Component } from "react";
import "./card.css";
import IM from "../../../images/IMG_3178.jpg";
import { Link } from "react-router-dom";
import { Url } from "../../..";
const Card = (props) => {
  const { Card } = props;
  console.log(props);
  console.log(Card._id);
  return (
    <div className="card-ed " style={{boxShadow:"0 0 1px lightgray",margin:"10px",padding:"0px"}}>
      {props.type === "city" ? (
        <Link className="img-link img-link-first" to={`/properties/city/${ Card.name?.trim().replaceAll(/\s+/g,'-')?.replaceAll('---','-')}`} state={{id:Card._id}}>
          <img className="card-img-top" src={(Url+Card.image).split('.').at(-1)==='comnull'?'/logo.svg':Url+Card.image} alt={Card.name} />
        </Link>
      ) : props.type === "property" ? ( 
        <Link className="img-link" to={`/property/${ Card?.slug}`} state={{id:Card._id}}>
          <img className="card-img-top" src={(Url+Card.image).split('.').at(-1)==='comnull'?'/logo.svg':Url+Card.image} alt={Card.name} />
        </Link>
      ) : (
        <Link className="img-link" to={`/properties/category/${ Card.name?.trim().replaceAll(/\s+/g,'-')?.replaceAll('---','-')}`} state={{id:Card._id, type: "category",name:Card.name }} >
          <img className="card-img-top" src={Url+Card.image} alt={Card.name} />
        </Link>
      )
      
     
      }

      <div className="card-body card-text-ctr">
        <div className="row">
        {props.type === "city" ? (
        <Link style={{textDecoration:"none"}} to={`/properties/city/${ Card.name?.trim().replaceAll(/\s+/g,'-')?.replaceAll('---','-')}`} state={{id:Card._id}}>
          <p className="blue-color upper semi-bold card-text">{Card.name}</p>
        </Link>
      ) : props.type === "property" ? ( 
        <Link style={{textDecoration:"none"}} to={`/property/${ Card?.slug}`} state={{id:Card._id}}>
          <p className="blue-color upper semi-bold card-text">{Card.name}</p>
        </Link>
      ) : (
        <Link style={{textDecoration:"none"}} to={`/properties/category/${ Card.name?.trim().replaceAll(/\s+/g,'-')?.replaceAll('---','-')}`} state={{id:Card._id, type: "category",name:Card.name }} >
          <p className="blue-color upper semi-bold card-text">{Card.name}</p>
        </Link>
      )
      
     
      }
         
        </div>

        <div className="row card-stays-ctr">
          {Card.stays != undefined ? (
            <div className="stays-mobile">
            <p className="card-text pr-2 blue-color reg-text">
              {Card.stays} Stays
            </p>
            </div>
          ) : (
            ""
          )}
          {Card.avg != undefined ? (
            <div className="avg-mobile">
            <p className="blue-color semi-bold card-text">Avg: ${Card.avg}</p>
            </div>
          ) : (
            ""
          )}
          {Card.price != undefined ? (
            <div className="price-mobile">
            <p className="blue-color semi-bold card-text">price: ${Card.price}</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
     
    </div>
  );
};
export default Card;
