import React from "react";
import Form from "../../form";
import Joi from "joi-browser";
import Axios from "axios";
import { toast } from "react-toastify";
import "./add.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css"
import { Url } from "../../../..";
class AddProperty extends Form {
  state = {
    data: {
      name: "",
      email: "",
      number: "",
      location: "",
      size: "",
      price: "",
      attachment: "",
    },
    errors: {},
    error: "",
  };
  schema = {
    email: Joi.string().required().email().label("Email Address"),
    name: Joi.string().required().label("Full Name"),
    number: Joi.string().required().min(10).label("Phone Number"),
    location: Joi.string().required().label("Property Location"),
    size: Joi.alternatives().try(Joi.number(), Joi.string()).required().label("Size (square meters)"),
    price: Joi.number().required().label("Price /night"),
    attachment: Joi.required().label("Attachment"),
  };
  doSubmit = async () => {
    const { data } = this.state;
    const formData = new FormData();
  
    // Append form data to the FormData object
    formData.append("name", data.name);
    formData.append("phone", data.number);
    formData.append("email", data.email);
    formData.append("size", data.size);
    formData.append("location", data.location);
    formData.append("price", data.price);
  
    // Append image attachments to the FormData object
    if (data.attachment && Array.isArray(data.attachment) && data.attachment.length > 0) {
      data.attachment.forEach((image) => formData.append("attachments", image));
    }
  
    try {
      const response = await Axios.post(
        Url+"/api/listing",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
  
      const dataObj = {
        name: "",
        email: "",
        number: "",
        location: "",
        size: "",
        price: "",
        attachment: "",
      };
  
      // Reset form data and display success message
      this.setState({ data: dataObj });
      toast.success("Property listed successfully");
    } catch (ex) {
      // Handle errors and display appropriate message to user
      let errorMsg = "An unexpected error occurred";
      if (ex.response && ex.response.status === 400) {
        errorMsg = ex.response.data.message;
      }
      toast.error(errorMsg);
    }
  };

   removeLocalStorage = () => {
    localStorage.removeItem('city')
    localStorage.removeItem('cityObj')
    localStorage.removeItem('checkIn')
    localStorage.removeItem('checkOut')
    localStorage.removeItem('guest')

  }
  componentDidMount(){
    this.removeLocalStorage();
  }
  handlePhoneChange = (value) => {
    const data = { ...this.state.data };
    data.number = value;
    this.setState({ data });
  };

  render() {
    return (
      <div className="add-mr">
        <div >
          <div className="row bac-sec-add">
            <div className="text1 container ">
            <h1> List your property</h1> <br />
          <p className="reg-text">
              
              Reach the guests you want—those who truly value your property—with
              Chalet Lebanon. Signing up is free, fast, and easy.
            </p>
            </div>
          </div>
        </div>
        <div className="container position-relative mt-3 mb-5 form-body">
          <div className=" mt-5 text-sec text2">
            <div className="row mb-2 ">
              <h5 className="semi-bold blue-color">
                Bring the perfect guests to your property
              </h5>
            </div>
            <div className="row ">
              <small className="med-text blue-color">
                Reach out to the millions of travelers whose needs, preferences,
                and price range make your property the ideal place to stay.
              </small>
            </div>
          </div>

          <div className="form-container position-absolute pl-4 pr-4">
            <div className="row pl-3 mt-4 mb-4">
              <h6 className="semi-bold blue-color">
                Check how much you could earn!
              </h6>
              <small className="light-text blue-color bold-ed">
                Tell us about your property and see how much you could earn by
                renting your place on Chalet Lebanon.
              </small>
            </div>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput(
                "name",
                "Full Name",
                "text",
                this.state.error,
                "input-field"
              )}
             <div className="input-field"  style={{marginBottom:"15px",padding:"10px",paddingTop:"5px",paddingBottom:"5px"}}>
          <PhoneInput
international
            defaultCountry="LB"
            value={this.state.data.number}
            onChange={this.handlePhoneChange}
            placeholder={"Phone Number"}
            
          />
          {this.state.errors && this.state.errors.number && (
            <span className="error">{this.state.errors.number}</span>
          )}
        </div>
              {this.renderInput(
                "email",
                "Email Address",
                "email",
                this.state.error,
                "input-field"
              )}
              {this.renderInput(
                "location",
                "Property Location",
                "text",
                this.state.error,
                "input-field"
              )}
              {this.renderInput(
                "size",
                "Size (square meters)",
                "text",
                this.state.error,
                "input-field"
              )}
              {this.renderInput(
                "price",
                "Price /night",
                "number",
                this.state.error,
                "input-field"
              )}
              {this.renderInput(
                "attachment",
                "Attachment",
                "file",
                this.state.error,
                "input-field"
              )}
              {this.renderButton(
                "Submit",
                "submit-button mt-3 mb-5 light-text blue-color prop-light"
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default AddProperty;
