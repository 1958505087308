import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams, useLocation,useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
 import { Navigation } from "swiper";
//import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Axios from "axios";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import SearchFooter from "../../footer/searchFooter";
import SearchBar from "../../navbar/searchbar";
import home from "../../../../svg/home.svg";
import bed from "../../../../svg/Group 29.svg";
import bathroom from "../../../../svg/Group 22.svg";
import WhatsApp from "../../../../svg/Group 234.svg";
import Clock from "../../../../svg/Group 31.svg";
import DatePicker from "react-datepicker";
import GoogleMap from "../properties/map";
import "react-datepicker/dist/react-datepicker.css";
import "./property.css";
import { Url } from "../../../..";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { Modal } from "@mui/material";
// import Calendar from "../calendar";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays } from "date-fns";
import { DateRange } from "react-date-range";
import { Calendar } from "react-multi-date-picker";
import "react-multi-date-picker/styles/colors/green.css";
// import "react-multi-date-picker/styles";
import "react-multi-date-picker/styles/layouts/mobile.css";
import "react-multi-date-picker/styles/layouts/prime.css";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { searchLoading } from "../../../../App";
function Property() {
  const [property, setProperty] = useState({});
  const [properties, setProperties] = useState([]);
  const [fetch, setFetch] = useState(false);
  const [datePickerIsOpen, setdatePickerIsOpen] = useState(false);
  const [places, setPlaces] = useState([]);
  const [blocked, setBlocked] = useState([]);
  const [showGallery, setShowGallery] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [range, setRange] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const { removeLocalStorage } = useContext(searchLoading);
  const navigate = useNavigate()
  const [state, setState] = useState([
    {
      startDate: addDays(new Date(), 1),
      endDate: null,
      key: "selection1",
      color: "#073448",
    },
    {
      startDate: addDays(new Date(), 4),
      endDate: addDays(new Date(), 8),
      key: "selection2",
      color: "#073448",
    },
    {
      startDate: addDays(new Date(), 8),
      endDate: addDays(new Date(), 10),
      key: "selection3",
      color: "#073448",
    },
  ]);

  const [defaultProps] = useState({
    center: {
      lat: 33.88863,
      lng: 35.49548,
    },
    zoom: 11,
  });
  const [city, setCity] = useState("");
  const [type, setType] = useState("");
  const styleDate = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    // width:screenWidth > 992? "50%":"80%",
    height: "auto",
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    // p: "20px 0px",
    textAlign: "center",
  };
  const { typee, propertyName } = useParams();

  // console.log(name ,propertyName);
  const location = useLocation();

  // const cities = JSON.parse(localStorage.getItem("cities"));
  // console.log("cities", cities);
  // console.log(
  //   "cityId",
  //   cities?.filter(
  //     (data) =>
  //       data.name?.trim()?.replaceAll(/\s+/g, "-") ==
  //       name?.trim()?.replaceAll(/\s+/g, "-")
  //   )
  // );
  // let id =cities?.filter(data => data.name?.trim()?.replaceAll(/\s+/g, '-')==name?.trim()?.replaceAll(/\s+/g, '-'))[0]._id
console.log(propertyName);
  function handleShowGallery() {
    setShowGallery(true);
  }
  const handleChange = (item) => {
    console.log(item);
    setState((prev) => [...prev, item]);
  };
  

  const getAllProperties = async () => {
    const { data } = await Axios.get(
      Url + `/api/home/property?pageSize=` + 1000 + "&pageNumber=" + 1
    );
    setProperties(data.properties);
  };

  useEffect(() => {
    console.log(window.location.origin+""+location.pathname);
    removeLocalStorage()
    getAllProperties();
  }, []);
  const id = location.state?.id
    ? location.state?.id
    : properties.filter(
        (prop) =>
          prop.slug ==
          propertyName
      )[0]?._id;
  console.log(properties);
  console.log(
    properties?.filter(
      (prop) =>
        prop.slug ==
        propertyName
    )
  );
  console.log(
    properties?.filter(
      (prop) =>
        prop.slug ==
        propertyName
    )
  );
  const cityName = properties?.filter(
    (prop) =>
      prop.slug ==
      propertyName
  )[0]?.city;

  useEffect(() => {
    console.log(id);
  }, [id]);

  useEffect(() => {
    console.log(cityName);
  }, [cityName]);
  useEffect(() => {
    console.log(range);
  }, [range]);
  const getCalendar = async () => {
    const { data } = await Axios.get(Url + `/api/calendar?property=${id}`)
    const events = [];
    data.calendar.forEach((ele) => {
      let event = {};
      let dateNow = new Date()
      let start = new Date(ele.startDate);
      console.log("start", new Date(start));
      let end = new Date(ele.endDate);
      end.setDate(end.getDate() - 1);
      let yesterday = new Date(end.getTime);
      yesterday.setDate(yesterday.getDate() - 1);
      console.log("end", end);
if (( start.getMonth() >= dateNow.getMonth() && end.getMonth() >=  dateNow.getMonth())) {
  if (ele.recordType === "booking")
    event = [
      // id: ele._id,
      // title: ele.notes,
      start,
      end,
    ];
  else
    event = [
      // id: ele._id,
      // title: ele.notes,
      start,
      end,
      // color: 'red',
    ];

}
      events.unshift(event);
    });
    console.log(events);
    setRange(events);
  };
  function handleCloseGallery() {
    setShowGallery(false);
  }

  function handlePrevImage() {
    setSelectedImage(
      (selectedImage - 1 + property.images.length) % property.images.length
    );
  }

  function handleNextImage() {
    setSelectedImage((selectedImage + 1) % property.images.length);
  }

  const getData = async () => {
    setLoading(true);
    setType(typee);
    setCity(cityName);
    const { data } = await Axios.get(Url + `/api/home/property/` + id);
    //    for(let i=0;i<data.property.bedRooms.length;i++)
    //    {
    //     for(let j=0;j<data.property.bedRooms[i].beds.length;j++)
    //     {
    //         console.log('bed',j,data.property.bedRooms[i].beds[j])
    //     }
    //    }
    console.log(data);
    setPlaces(data.property.map);
    console.log('places',places)
    let dates = [];
    console.log(data);
    data.dates.forEach((e) => {
      dates.push(new Date(e));
    });

    setBlocked(dates);
    setProperty(data.property);
    console.log('property',data.property)
    //    setpopuler(data.popular)
    //    setproperties(data.properties)
    //    setCount(data.count)
    setFetch(true);
    setLoading(false);
  };
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (id) {
      getData();
      getCalendar();
    }
  }, [id, properties]);
  useEffect(() => {
    const handleClick = (event) => {
      if (event.target.classList.contains("info-window-name")) {
        const placeName = event.target.getAttribute("data-id");
        navigate(`/property/${ placeName?.toLowerCase().trim().replaceAll(/\s+/g,'-')?.replaceAll('---','-')}`); // Navigate dynamically
      }
      
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [navigate]);
  const getInfoWindowString = (place) => `
    <div>
      <div style="font-size: 16px; cursor: pointer;" data-id="${place.name}" class="info-window-name">
        ${place.name}
      </div>
      <div style="font-size: 14px;">
        <span style="color: grey;">
        price: $${place.price}
        </span>
       
      </div>
     
    
    </div>`;
  const handleApiLoaded = (map, maps, places) => {
    const markers = [];
    const infowindows = [];
    places.forEach((place) => {
      markers.push(
        new maps.Marker({
          position: {
            lat: parseFloat(place.lat),
            lng: parseFloat(place.long),
          },
          map,
        })
      );
      infowindows.push(
        new maps.InfoWindow({
          content: getInfoWindowString(place),
        })
      );
    });

    markers.forEach((marker, i) => {
      marker.addListener("click", () => {
        infowindows[i].open(map, marker);
        map.setCenter(marker.getPosition()); 
      map.setZoom(15);  
      });
    });
    
  };
  const handlesetdatePickerIsOpen = () => {
    setdatePickerIsOpen(!datePickerIsOpen);
  };

  const openImageModal = () => {
    setShowImageModal(true);
  };
  const closeImageModal = () => {
    setShowImageModal(false);
  };
  // var screenWidth = localStorage.getItem("screenWidth");

  const AnyReactComponent = ({ text }) => <div>{text}</div>;
  if (fetch)
    return (
      <React.Fragment>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleDate} className="check-calender" >
            <Calendar
              weekStartDayIndex={6}
              // className="green"
              onlyShowInRangeDates={true}
             // value={range}
              onChange={handleChange}
              showOtherDays={false}
              minDate={new Date()}
              multiple
              // disabled
              defaultValue={new Date()}
              readOnly
              numberOfMonths={screenWidth > 430 ? 2 : 1}
              // showOtherDays
              range
            />

            {/* <DateRangePicker
            onChange={item => setState({ ...state, ...item })}
            ranges={state}
            
          /> */}
            {/* <DateRange
            editableDateInputs={true}
            onChange={item => handleChange(item)}
            moveRangeOnFirstSelection={false}
            ranges={state}
            months={1}
            rangeColors={"#073448"}
          /> */}
            {/* <Calendar/> */}
          </Box>
        </Modal>
        <SearchBar />

        <div className="container search-mr mb-5 third-page-ctr" 
        // style={{maxWidth:screenWidth <= 768?"90%":"100%"}}
        >
          <div className="row property-hr">
            <hr id="horizintal" className="hide-row" />
          </div>
          {loading ? (
            <>
              <Box sx={{ display: "flex", margin: "100px auto" }}>
                <CircularProgress style={{ color: "#073448" }} />
              </Box>
            </>
          ) : (
            <>
              <div className="row search-sec-ctr">
                <div className="row" style={{ marginLeft: 0 }}>
                  <div className="col-12 mb-2" style={{ paddingLeft: 0 }}>
                    {type === "city" ? (
                      <span className="light-text blue-color prop-light">
                        Lebanon / {city}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-12 mb-3">
                    {type === "city" ? (
                      <h5 className="semi-bold blue-color">
                        {" "}
                        Chalets and bangalows at {city}
                      </h5>
                    ) : (
                      <h5 className="semi-bold blue-color">
                        {" "}
                        {city} / {property.name}
                      </h5>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginLeft: 0 }}>
                  {screenWidth >= 992 ? (
                    <div className="mob-pic col-sm-12 col-md-12 col-lg-9 col-xl-9 p-0">
                      <div className="row" style={{ marginLeft: 0 }}>
                        <div
                          className="col-sm-12 col-md-12 col-lg-8  "
                          style={{ paddingLeft: 0 }}
                        >
                          <img
                            id="main-image"
                            src={((Url + property.images[0]).split('.').at(-1)==='comnull'||(Url + property.images[0]).split('.').at(-1)==='comundefined')?'/logo.svg':Url + property.images[0]}
                            alt=""
                          />
                        </div>
                        <div className="col-sm-12 col-md-12 col-lg-4 p-0">
                          <div className="row">
                            <div className="col-12">
                              <img
                                id="sub-image"
                                src={((Url + property.images[1]).split('.').at(-1)==='comnull'||(Url + property.images[1]).split('.').at(-1)==='comundefined')?'/logo.svg':Url + property.images[1]}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6  pr-1">
                              <img
                                className="small-image"
                                src={((Url + property.images[2]).split('.').at(-1)==='comnull'||(Url + property.images[2]).split('.').at(-1)==='comundefined')?'/logo.svg':Url + property.images[2]}
                                alt=""
                              />
                            </div>
                            <div className="col-lg-6  pl-1">
                              <img
                                className="small-image"
                                src={((Url + property.images[3]).split('.').at(-1)==='comnull'||(Url + property.images[3]).split('.').at(-1)==='comundefined')?'/logo.svg':Url + property.images[3]}
                                alt=""
                              />
                            </div>
                          </div>
                          {property.images.length > 4 && (
                            <button
                              className="showMore btn btn-primary"
                              onClick={handleShowGallery}
                            >
                              Show more
                            </button>
                          )}
                        </div>
                      </div>

                      {showGallery && (
                        <div className="gallery-overlay">
                          <button
                            className="close-button"
                            onClick={handleCloseGallery}
                          >
                            x
                          </button>
                          <div className="gallery">
                            {property.images.map((image, index) => (
                              <img
                                key={index}
                                src={Url + image}
                                alt=""
                                style={{
                                  display:
                                    index === selectedImage ? "block" : "none",
                                }}
                              />
                              // <div className="image_gallery" style={{backgroundImage:`url(${Url + image})`}}>
                                
                              // </div>
                            ))}
                            <>
                              <button
                                className="prev-button"
                                onClick={handlePrevImage}
                              >
                                {"<"}
                              </button>
                              <button
                                className="next-button"
                                onClick={handleNextImage}
                              >
                                {">"}
                              </button>
                            </>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Swiper
                     // freeMode={true}
                      // pagination={{
                      //   el: ".swiper-pagination-11",
                      //   clickable: true,
                      // }}
                      // navigation={{
                      //   prevEl: ".swiper-button-prev-11",
                      //   nextEl: ".swiper-button-next-11",
                      // }}
                      // modules={[Navigation]}
                      modules={[Navigation]} // Import navigation module
                      navigation={{
                        nextEl: '.swiper-button-next', // Class for the next button
                        prevEl: '.swiper-button-prev', // Class for the previous button
                      }}
                      style={{position:"relative",right:"0px"}}
                      className="property-slider"
                     // grabCursor={true}
                      breakpoints={{
                        0: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },
                        500: {
                          slidesPerView: 1,
                          spaceBetween: 0,
                        },

                        991: {
                          slidesPerView: 3,
                          spaceBetween: 0,
                        },
                        1300: {
                          slidesPerView: 4,
                          spaceBetween: 0,
                        },
                        2560: {
                          slidesPerView: 6,
                          spaceBetween: 0,
                        },
                      }}
                    >
                      {property.images.map((image, index) => (
                        <SwiperSlide>
                          <div className="card-ed" style={{padding:"0px"}} key={index}>
                            
                            <img key={index} src={Url + image} alt="" />
                          </div>
                        </SwiperSlide>
                      ))}
                       <div className="swiper-button-next" style={{zIndex:"99",right:"30px",backgroundColor:"lightgray"}}>
                          {/* <IoIosArrowForward style={{width:"20px",height:"20px"}}/> */}
                          <IoIosArrowBack style={{width:"20px",height:"20px"}}/>
                       </div>
                       <div className="swiper-button-prev" style={{zIndex:"99",left:"10px",backgroundColor:"lightgray"}}>
                          <IoIosArrowBack style={{width:"20px",height:"20px"}}/>
                       </div>
                    </Swiper>
                  )}

                  <div className="mob-row3  col-sm-12 col-md-12 col-lg-3 col-xl-2 position-relative buttons-ctr " style={{padding:screenWidth <= 768 && 0 , gap:20,justifyContent:screenWidth > 1000 ? "start":"space-between",alignItems:"start", flexDirection:screenWidth > 992 ? "row":"row-reverse",paddingLeft:0}}>
                    <div className=" d-flex mt-2"
                    //style={{order:screenWidth > 768 ? 1:2,}}
                    >
                      <h4 className="med-text blue-color price ">
                        ${property.price}
                      </h4>
                      <span className="light-text blue-color prop-light avg ">
                        avg/night
                      </span>
                    </div>
                    <div className="mt-3" style={{display:"flex",flexDirection:screenWidth>768?"column":"column",gap:20}}>
                    <div className="" style={{padding:screenWidth <= 768 && 0,width:"200px"}}>
                      <a style={{color:'#fff'}} href={`https://wa.me/${property?.agent?.phoneNumber}?text=Hi,%20I'm%20interested%20in%20this%20stay:%20${property?.name?.trim()?.replaceAll(" ","%20")}%20${window.location.origin+""+location.pathname}&app_absent=0`} target='_blank' >
                        <button className=" book" type="submit">
                          <img src={WhatsApp} className="book-img" alt="" />{" "}
                          Book Now
                        </button>
                      </a>
                    </div>
                      {/* <DatePicker
                    highlightDates={blocked}
                    withPortal
                    onClickOutside={handlesetdatePickerIsOpen}
                    monthsShown={2}
                    selectsRange
                    
                    startDate={range.start}
                    endDate={range.end}
                    // inline
                    shouldCloseOnSelect={false}
                    customInput={
                      <button
                        style={{ marginBottom: "20px" }}
                        className=" available"
                        onClick={handlesetdatePickerIsOpen}
                      >
                        Check Availability
                      </button>
                    }
                  /> */}
                      <button
                        style={{ marginBottom: "20px",width:"200px"  }}
                        className={
                          screenWidth > 992
                            ? " available "
                            : "available "
                        }
                        onClick={() => handleOpen()}
                      >
                        Check Availability
                      </button>
                    
                    </div>
                    <div className="vl"></div>
                  </div>
                </div>
                <div
                  className="row mt-3 position-relative sec-row"
                  style={{ marginLeft: 0,columnGap:35,flexWrap:screenWidth < 768 &&"nowrap",overflow:screenWidth < 768 &&"auto" }}
                >
                  <div className="active">
                    <a
                      className="active"
                      onClick={() => {
                        const element = document.getElementById("about");
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }
                      }}
                    >
                      About{" "}
                    </a>
                  </div>
                  <div
                    onClick={() => {
                      const element = document.getElementById("rooms");
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    <a className=" clickAble light-text blue-color prop-light">
                      Rooms & beds
                    </a>
                  </div>
                  <div
                    onClick={() => {
                      const element = document.getElementById("amenities");
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    <a className=" clickAble light-text blue-color prop-light">
                      Amenities
                    </a>
                  </div>
                  <div
                    onClick={() => {
                      const element = document.getElementById("policies");
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    <a className=" clickAble light-text blue-color prop-light ">
                      Policies
                    </a>
                  </div>
                  <div
                    onClick={() => {
                      const element = document.getElementById("mapSec");
                      if (element) {
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    <a className=" clickAble light-text blue-color prop-light">
                      Map
                    </a>
                  </div>

                  <div className="row">
                    <hr id="horizintal property-hr" />
                  </div>

                  <hr className="active-line active-det" />
                </div>
                <div className=" mt-2 mb-3" id="about">
                  <h5 className="about-this-rental semi-bold blue-color">
                    About this rental
                  </h5>
                </div>
                <div className="mob-row4 row" style={{ marginLeft: 0 }}>
                  <div
                    className="col-sm-12 col-md-6 col-xl-3 mb-4"
                    style={{ paddingLeft: 0 }}
                  >
                    <div className="row" style={{ marginLeft: 0 }}>
                      <div className="col-4" style={{ paddingLeft: 0 }}>
                        <img id="icon" src={home} alt="" />
                      </div>
                      <div className="col-7">
                        <div className="row">
                          <h5 className="semi-bold blue-color">Condo</h5>
                        </div>
                        <div className="row">
                          <span className="light-text blue-color prop-light ">
                            {property.size} sq.Ft
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-sm-12 col-md-6 col-xl-3  mb-4"
                    style={{ paddingLeft: 0 }}
                  >
                    <div className="col-1 line">
                      <hr id="ver-line-small" />
                    </div>
                    <div className="row" style={{ marginLeft: 0 }}>
                      <div className="col-4" style={{ paddingLeft: 0 }}>
                        <img id="icon" src={bed} alt="" />
                      </div>
                      <div className="col-8">
                        <div className="row">
                          <h5 className="semi-bold blue-color">
                            {property.bedRooms.length} {" "} bedroom
                          </h5>
                        </div>
                        <div className="row">
                          <span className="light-text blue-color prop-light">
                            {property.bedRooms.length} {" "} bed
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-sm-12 col-md-6 col-xl-3 mb-4"
                    style={{ paddingLeft: 0 }}
                  >
                    <div className="col-1 line">
                      <hr id="ver-line-small" />
                    </div>
                    <div className="row" style={{ marginLeft: 0 }}>
                      <div className="col-4" style={{ paddingLeft: 0 }}>
                        <img id="icon" src={bathroom} alt="" />
                      </div>
                      <div className="col-8">
                        <div className="row">
                          <h5 className="semi-bold blue-color">
                            {property.bathRooms.length} {" "} bathroom
                          </h5>
                        </div>
                        <div className="row">
                          <span className="light-text blue-color prop-light">
                            {property.bathRooms.map((space, index) =>
                              index === property.bathRooms.length - 1
                                ? space.bathType
                                : space.bathType + " " + " , "
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-sm-12 col-md-6 col-xl-3"
                    style={{ paddingLeft: 0 }}
                  >
                    <div className="col-1 line">
                      <hr id="ver-line-small" />
                    </div>
                    <div className="row" style={{ marginLeft: 0 }}>
                      <div className="col-4" style={{ paddingLeft: 0 }}>
                        <img id="icon" src={home} alt="" />
                      </div>
                      <div className="col-8">
                        <div className="row">
                          <h5 className="semi-bold blue-color">Spaces</h5>
                        </div>
                        <div className="row">
                          <span className="light-text blue-color prop-light">
                            {property.spaces.map((space, index) =>
                              index === property.spaces.length - 1
                                ? property.spaces[index]
                                : property.spaces[index] + " " + " , "
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4 mb-2 property-hr" style={{ marginLeft: 0 }}>
                  <hr id="horizintal" />
                </div>
                {property.description&& <div style={{ width: "100%" }} className="mt-2 mb-3">
                  <span className="light-text blue-color prop-light">
                    {property.description}
                  </span>
                </div> }
               {property.description&& <div className="row  property-hr" style={{ marginLeft: 0 }}>
                  <hr id="horizintal" />
                </div>}
                <div
                  style={{ width: "100%" }}
                  className=" mt-2 mb-3"
                  id="rooms"
                >
                  <h5 className="semi-bold blue-color">Rooms & Beds</h5>
                </div>

                {property.bedRooms.map((bedRoom, index) => (
                  <>
                    <div key={index} className="row mt-2 mb-3">
                      <div className="col-12">
                        <div style={{ width: "100%" }} className=" d-flex flex-wrap">
                          <h5 className="semi-bold blue-color mr-1">
                            {bedRoom.name + ":  "}
                          </h5>
                          <h5 className="light-text blue-color bold-ed">
                            {`(Sleeps: ${bedRoom.numberOfPeople})`}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {bedRoom.beds.map((b, index) => (
                        <div
                          key={index}
                          className="  col-sm-12 col-md-12 col-lg-6 mb-3"
                        >
                          <div className="bed-info">
                            <div className="row " style={{rowGap:10,alignItems:"center"}}>
                              <div className="col-3">
                                <img src={bed} alt="" style={{width:"50px",height:"50px",marginRight:"10px"}}/>
                              </div>
                              <div className="col-9 text-break ">
                                <h5 className="semi-bold blue-color">
                                  {b.bedAt} -
                                  <span className="light-text blue-color bold-ed">
                                    {" "}
                                    {b.bedType}
                                  </span>
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ))}
                <div
                  className="row mt-4 mb-4 property-hr"
                  style={{ marginLeft: 0 }}
                >
                  <hr id="horizintal" />
                </div>
                <div className="row mb-3" id="amenities" style={{marginLeft:"0px",marginRight:"0px"}}>
                  <div className="col-12" style={{padding:'0px'}}>
                    <h5 className="semi-bold blue-color">Amenities</h5>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12" style={{paddingLeft:"0px",paddingRight:"0px"}}>
                    <div className="row mb-3" style={{marginLeft:"0px",marginRight:"0px"}}>
                      {property.amenities.map((amenity, index) => (
                        <div
                          key={index}
                          className="col-xl-4  col-sm-6 mb-4 col-6"
                        >
                          <span className="light-text blue-color prop-light" style={{fontSize:"14px"}}>
                            <img
                              src={Url + amenity.icon}
                              alt=""
                              className=" icon-img"
                               style={{marginRight:"10px"}}
                            />
                            {amenity.name}{" "}
                          </span>

                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-6"></div>
                </div>
                <div
                  className="row mt-4 mb-4 property-hr"
                  style={{ marginLeft: 0 }}
                >
                  <hr id="horizintal" />
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-4" id="policies">
                      <h5 className="semi-bold blue-color">Policies</h5>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <h5 className="semi-bold blue-color">Cancellation Policy</h5>
                </div>

                <div className="row mt-3 mb-3">
                  <div className="col-12">
                    <span className="light-text blue-color prop-light">
                      {property.cancelationPolicy}
                    </span>
                  </div>
                </div>
                <div
                  className="row mt-4 mb-4 property-hr"
                  style={{ marginLeft: 0 }}
                >
                  <hr id="horizintal" />
                </div>
                <div className="mb-3 mt-3">
                  <h5 className="semi-bold blue-color">
                    Damage and incidentals
                  </h5>
                </div>
                <div className="row mt-3 mb-3">
                  <div className="col-12">
                    <span className="light-text blue-color prop-light">
                      {property.damagePolicy}
                    </span>
                  </div>
                </div>
                <div
                  className="row mt-4 mb-4 property-hr"
                  style={{ marginLeft: 0 }}
                >
                  <hr id="horizintal" />
                </div>

                <div className="row">
                  <div className=" mb-4 hours-rules">
                    <h5 className="semi-bold blue-color">Hours Rules</h5>
                  </div>
                  <div className="col-sm-12 col-md-12 col-xl-5">
                    <div className="row mb-3">
                      <div className="col-2 mr-3">
                        <img src={Clock} alt="" className="mr-3" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-xl-8 mt-3">
                        <h6 className="semi-bold blue-color">
                          Check In after:{" "}
                          <span className="light-text blue-color prop-light">
                            {property.checkInTime}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-xl-5">
                    <div className="row mb-3">
                      <div className="col-2 ">
                        <img src={Clock} alt="" className="mr-3" />
                      </div>
                      <div className="col-sm-12 col-md-12 col-xl-8 mt-3">
                        <h6 className="semi-bold blue-color">
                          Check Out after:{" "}
                          <span className="light-text blue-color prop-light">
                            {property.checkOutTime}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row mt-4 mb-4 property-hr"
                  style={{ marginLeft: 0 }}
                >
                  <hr id="horizintal" />
                </div>
                <div className="row">
                  <div className="col-12">
                    {property.rules.map((rule, index) => (
                      <div key={index} className="row mb-3">
                        <div className="col-12">
                          <span className="light-text blue-color prop-light">
                            <img
                              src={Url + rule.icon}
                              alt=""
                              className="mr-3 icon-img"
                            />
                            {rule.name}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  className="row mt-4 mb-4  property-hr"
                  style={{ marginLeft: 0 }}
                >
                  <hr id="horizintal" />
                </div>
                <div className="row" style={{margin:screenWidth<768&&0,padding:screenWidth<768&&0}}>
                  <div className="col-sm-12 col-md-12 col-lg-6" style={{padding:screenWidth<768&&0}}>
                    <div className="mb-4" id="mapSec">
                      <h5 className="semi-bold blue-color">Location Map</h5>
                    </div>

                    <div className="mb-4">
                      <h5 className="semi-bold blue-color">What's Nearby</h5>
                    </div>
                    {property.map.map((near, index) => (
                      <div key={index} className="mb-3">
                        <span className="light-text blue-color prop-light">
                          {index + 1}. {near.name}
                        </span>
                      </div>
                    ))}
                  </div>
                  <div className=" col-sm-12 col-md-12 col-lg-6" style={{padding:screenWidth<768&&0,marginLeft:screenWidth<768&&0}}>
                    <div id="map-det">
                      {places.length > 0 && (
                        <GoogleMap
                          bootstrapURLKeys={{
                            key: process.env.REACT_APP_MAP_KEY,
                          }}
                          center={{
                            lat: parseFloat(property.location.lat),
                            lng: parseFloat(property.location.long),
                          }}
                          defaultZoom={defaultProps.zoom}
                          yesIWantToUseGoogleMapApiInternals
                          onGoogleApiLoaded={({ map, maps }) =>
                            handleApiLoaded(map, maps, places)
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
        <SearchFooter />
      </React.Fragment>
    );
}
export default Property;
